import {
  useEffect,
  useState,
  useRef,

} from 'react';

import {
  useParams,
  useSearchParams,
  Link,
  useNavigate
} from 'react-router-dom';

import api from "../utils/api";
import constants from "../utils/constants";

import Masonry from 'react-masonry-css';


import renderLoading from '../components/loading';

export default function AlbumPage(){
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
	let {id} = useParams('id');

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  
  useEffect(() => {
    getAlbum(id);
  }, []);

  const getAlbum = async (id) => {
    setLoading(true)
    let res = await api.req('get', `/movie-albums/${id}`, {}, true);
    setLoading(false);
    if (res.status === 200) {
      setData(res.data);
    }else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/albums/'+id);
    }
  };

  
	const handelPlay = (item)=>{
    if(item.episodeId){
      navigate("/player/"+item.id+'?episode_id='+item.episodeId)
    }else{
      navigate("/player/"+item.id)
    }
	}

	if(data==null){
		return renderLoading();
	}
  console.log(data,'data')
  return(
		<div className="album-page ug">
			<div className="album">
				<img src={constants.cdn+data.album.cover+'?x-oss-process=image/resize,w_750,limit_0'} alt="" className="cover" />
				
			</div>
		
    <div className='movies-section'>
        <div className="section-header">
          <div className="name">
            <span>※</span>
            {data.album.name}
          </div>
          <div className="sub-name">({data.movies.length} تال)</div>
        </div>
        <div className="section-content" >
          
          
            <Masonry
              className="movies"
              breakpointCols={3}
              columnClassName="my-masonry-grid_column">
            {
              

              data.movies.map((item,index)=>{
                return(
                  <div onClick={()=>handelPlay(item)} className="movie" key={index}>
                    <div className="cover-box">
                      <img src={item.cover.startsWith('orhan')?constants.cdn+item.cover+'?x-oss-process=image/resize,w_220,limit_0':item.cover} alt={item.name} className="cover" />
                    </div>
                    <div className="name">{item.name}</div>
                  </div>
                )
              })
            }
            </Masonry>

        </div>
        <div className="section-footer"></div>
      </div>
				
		</div>
  )
}
