

import constants from "../utils/constants";

import remove from "../static/remove.png";
import {
  Link,
	useNavigate
} from 'react-router-dom';

export default function MoviesList (props){

	const navigate = useNavigate();

	const handelPlay = (item)=>{
    if(item.episodeId){
      navigate("/player/"+item.id+'?episode_id='+item.episodeId)
    }else{
      navigate("/player/"+item.id)
    }
	}
	if(props.movies!=null){
		if(props.movies.length==0){
			return(
				<div className="not-fount">ۋاقتىنچە مەزمۇن يوقكەن</div>
			)
		}else{
			return(
				<div className='movies-section'>
						<div className="section-header">
							<div className="name">
								<span>※</span>
								{props.name}
							</div>
							<div className="sub-name">({props.movies.length} تال)</div>
						</div>
						<div className="section-content">
							<div className="movies-list">
								{
									props.movies.map((item,index)=>{
										return(
											<div onClick={()=>handelPlay(item)}className="movie" key={index}>
												<div className="cover-box">
													<div className="cover" 
													style={
														{
															backgroundImage: `url(${constants.cdn+item.cover+'?x-oss-process=image/resize,w_220,limit_0'})`
														}
													}>
													</div>
													{
														item.episode_count>1&&item.update_state==0?<div className="update-status"><div className="text">يېڭىلاندى</div>
														<div className="num">{item.updated}/{item.episode_count}</div></div>:''

														
													}
													{
														item.episode_count>1&&item.update_state==1?<div className="update-status all-updated">
														{item.episode_count} قىسىم تاماملاندى
													</div>:''
													}
												</div>
												
												<div className="meta">
													
													<div className="name">{item.name}</div>

													<div className="episode">
														{item.episode_name}-قىسىم
													</div>
													<div className="progeress">
														{parseInt(item.played*100)}%
													</div>
													
												</div>
												<div onClick={(e)=>{props.handelRemoveView(e,item)}} className="remove">
													<img src={remove} alt="" className="remove-icon" />
												</div>
												
											</div>
										)
									})
								}
							</div>
						</div>
						<div className="section-footer"></div>
					</div>
			)
		}
	}
}