import "whatwg-fetch";

import {
  useEffect,
  useState,
  useRef,

} from 'react';

import {
  useParams,
  useSearchParams,
  Link,
  useNavigate
} from 'react-router-dom';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import ReactPlayer from "react-player";

import api from "../utils/api";
import constants from "../utils/constants";


import homeIcon from "../static/home.png";
import wechatIcon from "../static/wechat.png";
import favoriteIcon from "../static/favorite.png";
import favoritedIcon from "../static/favorited.png";

import allIcon from "../static/all.png";
import allListIcon from "../static/all-list.png";

import selectIcon from "../static/select.png";
import selectedIcon from "../static/selected.png";

import renderLoading from "../components/loading";

export default function PlayerPage() {
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState(null);

  const [showPaymentBox, setShowPaymentBox] = useState(false);

  const [playbackRateVideo, setPlaybackRateVideo] = useState(1);


	let {id} = useParams('id');
	const episodeId = searchParams.get('episode_id');

  const [movie, setMovie] = useState(null);
  const [episodes, setEpisodes] = useState(null);
  const [episode, setEpisode] = useState(null);

  const [showAllEpisodes, setShowAllEpisodes] = useState(true);

	const [showProducts,setShowProducts] = useState(true);

  const [playConfig, setPlayConfig] = useState(null);

  const [resolutions, setResolutions] = useState([]);

  const [playing, setPlaying] = useState(false);
	const [products,setProducts] =  useState(null);

  const [playHistory,setPlayHistory]=useState(null)
  const playerRef = useRef();

  //默认正序
  const [playlistOrder,setPlaylistOrder]=useState(false);


  useEffect(() => {
    getMovie(id);
		getProducts();
    
	
  }, []);

	



	// getConfigs();
  
	const getProducts = async () => {
    let res = await api.req('get', "/products", {
      orderBy: 'asc',
      sortBy: 'price'
    }, true);
    if (res.status === 200) {
      setProducts(res.data);
    }else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/player/'+id+'?episode_id='+episodeId);
    }
  };
  const getConfigs = async () => {
    let res = await api.req('get', "/configs", {
      // categoryId: 1
    }, false);
    if (res.status === 200) {
      let configs = {};
      res.data.map((item) => {
        configs[item.key] = item;
      });
      setConfigs(configs);
    }
  };

  

  const getMovie = async (id) => {
		if(!id){
			return;
		}
    let res = await api.req('get', `/movies/${id}`, {

    }, true);
    if (res.status === 200) {
			console.log(res.data,'movie')
      let movie = res.data;
			let orderBy = movie.update_state==1?'asc':'asc';
      // let orderBy = movie.update_state==1?'asc':'desc';
			setMovie(movie);
			getEpisodes(id,orderBy)
    } else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/player/'+id+'?episode_id='+episodeId);
    }
  };

  const getEpisodes = async (id,orderBy) => {
    let res = await api.req('get', `/episodes/`, {
			movieId: id,
			orderBy: orderBy,
			sortBy: 'created_at'
    }, true);
    if (res.status === 200) {
      setEpisodes(res.data);
			// 如果有来源episode_id， 播放当前episode
			if(episodeId){
        console.log(111)
				res.data.map((item,index)=>{
					if(episodeId==item.id){
						console.log('has episode id')
						getPlayConfig(index,item.id);
            getPlayHistory(id,episodeId);
					}
				})
			}else{
        console.log("here")

        getPlayConfig(0, res.data[0].id)
				
        // getLastPlayedEpisodeId(res.data,id);
        // getPlayHistory(id,res.data[0].id);
        
			}
			
    }else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/player/'+id+'?episode_id='+episodeId);
    }
  };

  

	const getPlayConfig = async(index,episodeId)=>{
		
		let res = await api.req('get', `/episodes/${episodeId}`, {
			movieId: id
    }, true);
    if (res.status === 200) {
      setEpisode(res.data);

			let data = res.data;
			data.episodeIndex = index;
			if (Array.isArray(data.src)) {
        let resolutions = data.src;
        let map = new Map();
        for(let item of resolutions){
          if (!map.has(item.video_quality_level)) {
            map.set(item.video_quality_level, item);
          };
        }
        let newResolutions = [...map.values()];
				setResolutions(newResolutions);
				data.src = newResolutions[0].url;
				//resolution index
				data.resolution = 0;
			}

			if(data.play_type>0){
				let isVip = await getUserVip();
				if(data.play_type==1){
					//vip
					if(isVip){
						setShowPaymentBox(false);
						setPlayConfig(data);
						setPlaying(true);
					}else{
						delete(data.src);
						setPlayConfig(data);
						setShowPaymentBox(true);
						console.log("buy please!")
					}
				}else if(data.play_type==2){
					//buy or vip
				}else if(data.play_type==3){
					//buy only

				}
			}else{
				//data.play_type==0, 免费
				setPlayConfig(data);
				setPlaying(true);
			}
		}else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/player/'+id+'?episode_id='+episodeId);
    }
	};
	const getUserVip = async() => {
		const token = localStorage.getItem("token");
		if(!token){
			console.log(movie,episode,'movie-episode')
			navigate('/auth?from=/player/'+id+'?episode_id='+episodeId)
			return;
		}
		let res = await api.req('get',`/vip-users/1`,{},true);
		if(res.status==200){
			const now = new Date().getTime();
      // console.log(res.data.expire_at * 1000,'at')
			if (now < res.data.expire_at * 1000) {
				return true;
			}
		}else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/player/'+id+'?episode_id='+episodeId);
      return false;
    }else if(res.status==404){
			return false;
		}
  };

  const getLastPlayedEpisodeId = async(episodes,id) => {
    console.log(id,'movie id')
		const token = localStorage.getItem("token");
		if(token){
      let res = await api.req('get',`/views/${id}`,{
        movieId: id
      },true);
      if(res.status==200){
        console.log(res,'PlayHistory last episode')
        setPlayHistory(res.data)
        setSearchParams({
            episodeId: res.data.episodeId
        })
  
        let episodeIndex = findEpisodeIndex(episodes,res.data.episodeId);
        console.log(episodeIndex,res.data.episodeId,'index-id')
  
        getPlayConfig(episodeIndex,res.data.episodeId)
      }else{
        getPlayConfig(0,episodes[0].id)
      }
			
		}else{
      getPlayConfig(0,episodes[0].id)
    }
  };

  const findEpisodeIndex = (episodes,episodeId)=>{
    let lastIndex = 0;
    // console.log('episodes=',episodes,episodeId)
    if(episodes&&episodes.length>1){
      episodes.map((item,index)=>{
        if(item.id==episodeId){
          console.log(index,'lastindex1')
          lastIndex=index;
        }
      })
      if(lastIndex>Math.floor(episodes.length/2)){
        console.log('倒序')
        episodes.reverse();
        setPlaylistOrder(true);
        episodes.map((item,index)=>{
          if(item.id==episodeId){
            console.log(index,'lastindex2')
            lastIndex=index;
          }
        })
      }
    }
    
    return lastIndex;
  };



  const getPlayHistory = async(id,episodeId) => {
    console.log(id,episodeId,'episod')
		const token = localStorage.getItem("token");
		if(!token){
			return;
		}
		let res = await api.req('get',`/views/${id}`,{
			movieId: id,
			episodeId: episodeId,
		},true);
		if(res.status==200){
			console.log(res,'PlayHistory')
      setPlayHistory(res.data)
		}else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/player/'+id+'?episode_id='+episodeId);
    }
  };
	const savePlayHistory = async(duration=0) => {
		const token = localStorage.getItem("token");
		if(!token){
			return;
		}
		let res = await api.req('post',`/views`,{
			movieId: parseInt(id),
			episodeId: episode.id,
			duration: duration,
		},true);
		if(res.status==201){
			console.log(res,'res1')
		}
  };

	const updatePlayHistory = async(times) => {

		if(parseInt(times.playedSeconds)%30==0){
			const token = localStorage.getItem("token");
			if(!token){
				
				
				return;
			}
			let res = await api.req('PATCH',`/views`,{
				movieId: id,
				episodeId: episode.id,
				duration: parseInt(times.playedSeconds),
				played: parseFloat(times.played).toFixed(5),
			},true);
			if(res.status==201){
				console.log(res,'res2')
			}
		}
		
  };

	
  

  const getResolution = (resolution) => {
    if (resolution.video_quality_level == 1) {
      return "تىز";
    } else if (resolution.video_quality_level == 2) {
      return "نورمال";
    } else if (resolution.video_quality_level == 3) {
      return "سۈزۈك";
    }
  };

  const handelResolution = (index) => {
    let newPlayConfig = { ...playConfig };
    newPlayConfig.resolution = index;
    newPlayConfig.src = resolutions[index].url;

    console.log(newPlayConfig, "newPlayConfig");
    setPlayConfig(newPlayConfig);
  };

  const handelEpisode = (index) => {
    setShowPaymentBox(false)
		navigate(`/player/${id}?episode_id=${episodes[index].id}`)
    setPlaying(false);
    getPlayConfig(index, episodes[index].id);
  };
  const renderEpisode = (episodes) => {
    return episodes.map((item, index) => {
      return (
        <div
          onClick={() => {
            handelEpisode(index);
          }}
          id="{{'episode-'+index}}"
          key={index}
          className={playConfig.id == item.id ? "episode current" : "episode"}
        >
          {item.name}
          {item.play_type>0?<span className="vip en">v</span>:""}
          
        </div>
      );
    });
  };

  const handelPayment = (product) => {
    createOrder(product);
  };

  const createOrder = async(product) => {
    let res = await api
      .req("post","/orders", {
        name: product.name,
				amount: product.price,
				productId: product.id,
        movie_id: id,
        episode_id: episodeId
      },true);
			if(res.status==201){
				payment(res.data);
			}
      
  };
  const payment = (data) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: "wx00be5279b25ccc09", //公众号ID，由商户传入
        timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: data.nonceStr, //随机串
        package: data.package,
        signType: data.signType, //微信签名方式：
        paySign: data.paySign, //微信签名
      },
      function (res) {
        console.log(res, "res3");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          toast.success("پۇل تۆلەندى", {
            position: toast.POSITION.TOP_RIGHT,
            className: "rtoast",
            onClose: () => {
              
            },
          });
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          getPlayConfig(
            playConfig.episodeIndex,
            episodes[playConfig.episodeIndex].id
          );
        } else {
          toast.warning("پۇل تۆلەش مەغلۇب بولدى", {
            position: toast.POSITION.TOP_RIGHT,
            className: "rtoast",
            onClose: () => {
              // props.history.push("/order?store_id="+cityList.id);
            },
          });
        }
      }
    );
  };


  const share = ()=>{
    
  }



  const handelEnded = () => {
    setPlaying(true);
    playNext();
  };

  const playNext = () => {
    console.log("playnext");
    let episodeIndex = playConfig.episodeIndex;
    if(playlistOrder){
      let nextepisodeIndex = episodeIndex - 1;
      if (nextepisodeIndex >-1) {
        getPlayConfig(nextepisodeIndex, episodes[nextepisodeIndex].id);
      }
    }else{
      let nextepisodeIndex = episodeIndex + 1;
      if (nextepisodeIndex < episodes.length) {
        getPlayConfig(nextepisodeIndex, episodes[nextepisodeIndex].id);
      }
    }
    
  };

  const toggleFavoratie = (movie)=>{
    let url = movie.isFaforited?'book-unfavorite':'book-favorite';
    api.request(url,{
      bookId: movie.id
    }).then(res=>{
      if(res.rc==1){
        let newMovie = {...movie};
        newMovie.isFaforited = !movie.isFaforited;
        setMovie(newMovie);
      }
    })
  }

	const renderPaymentBox = () => {
		if(!products){
			return renderLoading();
		}else if (showPaymentBox) {
      return (
        <div className="payment-box" >
          <div className="title">ئالىي ئەزا بولسىڭىز كۆرەلەيسىز</div>
          <div className="products">
					{
						products.map((product,index)=>{
							return(
								<div key={index} className="product">
									<div className="name">{product.name}</div>
									<div className="amount">
										{product.price/100} يۈەن
									</div>
									<div className="pay" onClick={()=>handelPayment(product)}>سېتىۋېلىش</div>
								</div>
							)
						})
					}
				</div>
        </div>
      );
    }
  };

	const renderResolutions = (resolutions) => {
    if (resolutions.length > 1) {
      return resolutions.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              handelResolution(index);
            }}
            className="resolution"
          >
            <img
              className="icon"
              src={playConfig.resolution == index ? selectedIcon : selectIcon}
            ></img>
            <div
              className={
                playConfig.resolution == index ? "text current" : "text"
              }
            >
              {getResolution(item)}
            </div>
          </div>
        );
      });
    }
  };

  if (!movie || !episodes||!playConfig) {
    return renderLoading();
  }
  return (
    <div className="app-page ug">
      <div className="player-page">
        <div className="player-box">
         
          <div className="player-wrapper" 	style={{ 
							backgroundImage: `url(${constants.cdn+movie.cover+'?x-oss-process=image/resize,w_750,limit_0'})` 
						}}>
            <ReactPlayer
              ref={playerRef}
              controls
              playing={playing}
              // light={constants.cdn + movie.cover}
              onEnded={() => {
                handelEnded();
              }}
              onReady={()=>{if(playHistory&&playHistory.duration>0){
                playerRef.current.player.seekOnPlay=playHistory.duration;
                setPlayHistory(null);
              }}}
							onStart={()=>{
								savePlayHistory()
							}}
							onProgress={(e)=>{
								updatePlayHistory(e)
							}}
							onDuration={(e)=>{
								console.log('onDuration:',e)
							}}
							onSeek={(e)=>{
								console.log('onSeek:',e)
							}}
							
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                  },
                },
              }}
              // light={false}
              loop={false}
              playbackRate={1}
							pip
              autoPlay={true}
              autobuffer="autobuffer" x5-playsinline="true" 
              playsinline={true} webkit-playsinline="true"
              className="react-player"
              url={playConfig ? playConfig.src : ""}
              width="100%"
              height="100%"
            />
            
						{renderPaymentBox()}

          </div>
        </div>

        <div className="resolutions">{renderResolutions(resolutions)}</div>

        <div className="header">
          <div className="right">
            <div className="name">{movie.name}</div>
            {/* {playConfig && movie.episode_count > 1 ? (
              <div className="episode">{playConfig.name}-قىسىم</div>
            ) : (
              ""
            )} */}
          </div>
          
          
          {/* <div className="left" >
            <img onClick={()=>{toggleFavoratie(movie)}}
              bindtap="bindCollection"
              className="icon favorite"
              src={movie.isFaforited?favoritedIcon:favoriteIcon}
            ></img>
            <div open-type="share" onClick={()=>share()} className="contact">
              <img className="icon moment" src={wechatIcon}></img>
            </div>
          </div> */}
       
        </div>

        <div className="episode-section">
				<div className="name">قىسىملىرى ({movie.episode_count})</div>
          {playConfig && movie.episode_count > 1 ? (
            <div className="playing-episode">{playConfig.name}-قىسىم</div>
          ) : (
            ""
          )}
          <img
            onClick={() => {
              setShowAllEpisodes(!showAllEpisodes);
            }}
            className="toggle"
            src={allIcon}
          />
        </div>

        <div className="episodes">
          <div
            scroll-into-div={
              episodes.length - 1 > playConfig.episodeIndex
                ? "episode-" + (playConfig.episodeIndex + 1)
                : "episode-" + playConfig.episodeIndex
            }
            className={showAllEpisodes ? "list-box show-all" : "list-box"}
          >
            {renderEpisode(episodes)}
          </div>
        </div>

        <ToastContainer rtl draggablePercent={60} autoClose={1000} />
      </div>
      <div className='footer'>
     
          <nav className='nav'>
            <div onClick={()=>{navigate('/')}}className='item'>باشبەت</div>
            {/* <div onClick={()=>{navigate('/moments')}}className='item'>كۆرۈرمەن</div> */}
            
            <div onClick={()=>{navigate('/user')}}className='item'>مېنىڭ</div>
          </nav>
         
        </div>
    </div>
  );
}

