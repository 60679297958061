import {
  useEffect,
  useState,
  useRef,

} from 'react';

import {
  useParams,
  useSearchParams,
  useNavigate,
  Link,
} from 'react-router-dom';
import api from "../utils/api";


import vipIcon from "../static/vip.png";


import Movies from '../components/movies';
import renderLoading from '../components/loading';

export default function CategoryPage(){
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  

	const params = useParams();
  const categoryId = params.id;
  // console.log(categoryId,'categoryId')
  let tagId = searchParams.get('tag');

   
  const pageContainerRef = useRef();

  const [loadingMore,setLoadingMore]=useState(false)


  const [loading, setLoading] = useState(false);

  const [category,setCategory] = useState(null);
  const [movies,setMovies] = useState(null);


  const [tags, setTags] = useState(null);


  useEffect(() => {
    getCategory();
    getMovies();
    // getConfigs();
    getTags();
  }, [tagId]);

  const getMovies = async (page=1) => {
    if(page==1){
      setLoading(true)
    }else{
      setLoadingMore(true)
    }

    let body = {
      categoryId: categoryId,
      page:page,
    }
    if(tagId){
      body.tagId= tagId
    }
    let res = await api.req('get', "/movies", body, true);
    if (res.status === 200) {
      let result = res.data;
      
      if(page==1){
        setLoading(false)
      }else{
        setLoadingMore(false)
        result.data = [...movies.data,...result.data];
      }
      // console.log('result=',result)
      setMovies(result);
    }else if(res.status==401){
      localStorage.removeItem("token")
      navigate('/auth?from=/category/'+categoryId);
    }
  };
  

  const loadMore =()=>{
    // console.log('loadMore',movies)
    if(movies.currentPage<movies.lastPage&&!loadingMore){
      getMovies(movies.currentPage+1);
    }
  }
  
  const getCategory = async () => {
    // console.log(categoryId,'categoryId')
    let res = await api.req('get', `/categories/${categoryId}`, {
      
    }, true);
    if (res.status === 200) {
      // console.log(res.data,'cats');
      setCategory(res.data);
    }
  };

  const getTags = async () => {
    let res = await api.req('get', `/tags`, {
      categoryId: categoryId
    }, true);
    if (res.status === 200) {
      // console.log(res.data,'cats');
      setTags(res.data);
    }
  };


  
  const handelNav = (item)=>{
    if(item.id==tagId){
      navigate("/category/"+categoryId);
    }else{
      navigate("?tag="+item.id);
    }
    
  }

  const renderTags = (tags)=>{
    if(tags==null){
      return renderLoading();
    }else{
      return(
        <div className="tags">
         {
          tags.map((item,index)=>{
            return(
              <div onClick={()=>{handelNav(item)}} key={index} className={item.id==tagId?'tag current':'tag'}>{item.name}</div>
            )
          })
         }
        </div>
      )
    }
  }
  
 
	if(movies==null||loading){
		return renderLoading();
	}
  return(
		<div className="category-page ug"  ref={pageContainerRef}>
			{/* <div className="category">
				<img src={category.cover} alt="" className="cover" />
			</div> */}
      {renderTags(tags)}

      
      <Movies name={category?category.name:''} movies={movies} loadMore={loadMore} 
      useWindow={true} scrollParentRef={pageContainerRef.current}/>
	
		</div>
  )
}
