import {
  useEffect,
  useState,
  useRef,

} from 'react';

import {
  useParams,
  useSearchParams,
  Link,
  useNavigate
} from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import Masonry from 'react-masonry-css';
import api from "../utils/api";
import '../sass/main.sass';

import search from "../static/search.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import constants from "../utils/constants";

import vipIcon from "../static/vip.png";
import arrowIcon from "../static/left.png";
import contactUs from "../static/opinion.png";
import format from 'date-format';


import {Pagination, EffectFade,Autoplay,
  Navigation } from 'swiper';

import { Swiper, SwiperSlide,useSwiper,useSwiperSlide, } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import parse from 'html-react-parser';


import renderLoading from '../components/loading';
import Movies from '../components/movies';
import MoviesList from '../components/movies_list';

export default function HomePage(){

  //common
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab')||0;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const swiperRef = useRef();

  //search page
  const [searchs,setSearchs]=useState([]);
  const [results,setResults]=useState(null);
  const searchRef = useRef();
  //homepage
  const [banners,setBanners] = useState(null);
  const [categories,setCategories] = useState(null);
  const [movies,setMovies] = useState(null);
  const [hotMovies,setHotMovies] = useState(null);
  
  const pageContainerRef = useRef();

  const [loadingMore,setLoadingMore]=useState(false)


  //userpage
  const [configs, setConfigs] = useState(null);
  const [user, setUser] = useState(null);
  const [userVip, setUserVip] = useState(null);
  const [products, setProducts] = useState(null);

  //albumpage
  const [albums, setAlbums] = useState(null);
  //views page
  const [views,setViews] = useState(null);
  const [showLogin,setShowLogin] = useState(false);
 
  useEffect(() => {
    
    switch (parseInt(tab)) {
      case 0:
        getBanners();
        getCategories();
        getHotMovies();
        getMovies();
        break;
      case 1:
        getAlbums();
        
        break;
      case 2:
        getViews();
        break;
      case 4:
        getUser();
        getConfigs();
        getProducts();
        getUserVip();
        
        break;
      default:
        getBanners();
        getCategories();
        getHotMovies();
        getMovies();
    }
  }, [tab]);

  const getBanners = async () => {
    let res = await api.req('get', "/banners", {
    }, true);
    if (res.status === 200) {
      // console.log(res.data,'banners');
      setBanners(res.data);
    }else if(res.status===401){
      navigate("/auth")
    }
  };

  const getHotMovies = async (page=1) => {
   
    let res = await api.req('get', "/movies/hot", {
     
    }, true);
    if (res.status === 200) {
      let result = res.data;
      setHotMovies(result);
    }
  };
  const getMovies = async (page=1) => {
    if(page==1){
      setLoading(true)
    }else{
      setLoadingMore(true)
    }
    let res = await api.req('get', "/movies", {
      sticky: 1,
      page:page,
    }, true);
    if (res.status === 200) {
      let result = res.data;
      
      if(page==1){
        setLoading(false)
      }else{
        setLoadingMore(false)
        result.data = [...movies.data,...result.data];
      }
      setMovies(result);
    }
  };

  const getViews = async () => {
    const token = localStorage.getItem("token");
    if(token){
      setLoading(true)
      let res = await api.req('get', "/views", {
      }, true);
      if (res.status === 200) {
        setLoading(false)
        setViews(res.data);
      }else if(res.status===401){
        // console.log("token expired!")
        localStorage.removeItem("token")
        setShowLogin(true);
      }
    }else{
      setShowLogin(true);
    }
  };

  const getSearchs = async () => {
    // console.log("search")
  
    const token = localStorage.getItem("token");
    if(token){
      setLoading(true)
      let res = await api.req('get', "/searchs", {
      }, true);
      if (res.status === 200) {
        setLoading(false)
        setSearchs(res.data);
      }else if(res.status===401){
        console.log("token expired!")
      }
    }
  };

  const getCategories = async () => {
    let res = await api.req('get', "/categories", {
      
    }, true);
    if (res.status === 200) {
      // console.log(res.data,'cats');
      setCategories(res.data);
    }
  };

  const getAlbums = async () => {
    let res = await api.req('get', "/mvoie-albums-movies", {
      
    }, true);
    if (res.status === 200) {
      // console.log(res.data,'albums');
      setAlbums(res.data);
    }
  };

  const getConfigs = async () => {
    let res = await api.req('get', "/configs", {
      // categoryId: 1
    }, true);
    if (res.status === 200) {
      let configs = {};
      res.data.map((item) => {
        configs[item.key] = item;
      });
      setConfigs(configs);
    }
  };

  const getProducts = async () => {
    let res = await api.req('get', "/products", {
      orderBy: 'asc',
      sortBy: 'price'
    }, true);
    if (res.status === 200) {
      
      setProducts(res.data);
    }
  };

  const getUser = async () => {
    let res = await api.req('get', "/users/1", {
    }, true);
    if (res.status === 200) {
      
      setUser(res.data);
    }
  };

  const getUserVip = async () => {
    let res = await api.req('get', "/vip-users/1", {
    }, true);
    if (res.status === 200) {
      setUserVip(res.data);
    }
  };

  const handelPayment = (product) => {
    createOrder(product);
  };

  const createOrder = async(product) => {
    let res = await api
      .req("post","/orders", {
        name: product.name,
				amount: product.price,
				productId: product.id
      },true);
			if(res.status==201){
				payment(res.data);
			}
      
  };
  const payment = (data) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: "wx00be5279b25ccc09", //公众号ID，由商户传入
        timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: data.nonceStr, //随机串
        package: data.package,
        signType: data.signType, //微信签名方式：
        paySign: data.paySign, //微信签名
      },
      function (res) {
        // console.log(res, "res3");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          toast.success("پۇل تۆلەندى", {
            position: toast.POSITION.TOP_RIGHT,
            className: "rtoast",
            onClose: () => {
              
            },
          });
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          getUserVip();
        } else {
          toast.warning("پۇل تۆلەش مەغلۇب بولدى", {
            position: toast.POSITION.TOP_RIGHT,
            className: "rtoast",
            onClose: () => {
              // props.history.push("/order?store_id="+cityList.id);
            },
          });
        }
      }
    );
  };

  
  const loadMore =()=>{
    if(movies.currentPage<movies.lastPage&&!loadingMore){
      getMovies(movies.currentPage+1);
    }
  }

  const handelPlay = (item)=>{
    if(item.episodeId){
      navigate("/player/"+item.id+'?episode_id='+item.episodeId)
    }else{
      navigate("/player/"+item.id)
    }
	}
  
  const renderBanners = ()=>{
    if(banners!=null&&banners.length>0)
    return (
      <div className='banners'>
        <Splide options={ {
          direction:"rtl",
          type:"loop",
          pagination:true,
          autoplay:true,
          rewind: true,
          interval:3000,
          arrows:true
        } }
        aria-label="banners" >
        {
          banners.map((item,index)=>{
            return(
              <SplideSlide key={item.id} onClick={()=>{navigate(item.link)}}>
               <img  
               className='splide-item'  src={constants.cdn+item.cover+'?x-oss-process=image/resize,w_750,limit_0'} alt="img"/>
              </SplideSlide>
            )
          })
        }
        </Splide>

        
      </div>
    ) 
  }

  const renderAlbums = ()=>{
    if(albums!=null)
    return albums.map((item,index)=>{
      return (
        <div className='albums' key={index} onClick={()=>navigate("/albums/"+item.album.id)}>
            <div className="album" style={
              {
                backgroundImage: `url(${constants.cdn+item.album.cover+'?x-oss-process=image/resize,w_750,limit_0'})`
              }
            }>
              
              <div onClick={()=>navigate("/albums/"+item.album.id)} className="name-count">
                <div className="name">{item.album.name}</div>
                <div className="count">{item.movies.length} تال</div>
              </div>
              <div className="movies">
                {
                  item.movies.map((movie,index)=>{
                    return(
                      <div onClick={()=>navigate("/player/"+movie.id)} className='movie' key={index}>
                        <img className='cover' src={constants.cdn+movie.cover+'?x-oss-process=image/resize,w_220,limit_0'} alt="img"/>
                        {/* <div className="name">{movie.name}</div> */}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          
          
        </div>
      )
    })
  }

  const renderCats = ()=>{
    if(categories!=null&&categories.length>0){
      return(
        <div className="cats">
          {
            categories.map((item,index)=>{
              return(
                <div onClick={()=>navigate("category/"+item.id)} className="cat" key={index}>
                  <img src={constants.cdn+item.icon} alt="" className="icon" />
                  <div className="name">{item.name}</div>
                </div>
              )
            })
          }    
        </div>
      )
      
    }
  }

  

  const handelTab = (index)=>{
    if(tab!=index){
      swiperRef.current.swiper.slideTo(index,400)
      setSearchParams({
        tab: index
      })
      navigate("/?tab="+index,true);
    }
  }
  const handelSplide = (link)=>{
   
    navigate(link)
  }
  const handelPageSwiper = (swiper)=>{
    // setTab(swiper.activeIndex)
    setSearchParams({
      tab: swiper.activeIndex
    })
    navigate("?tab="+swiper.activeIndex)
  }



	const handelRemoveView = async (e,item)=>{
		e.stopPropagation();
	
		const token = localStorage.getItem("token");
		if(!token){
			return;
		}
		let res = await api.req('PATCH',`/views`,{
			movieId: item.movieId,
			state: 1,
		},true);
		if(res.status==201){
			// console.log(res,'res2')
      getViews()
			toast.success("ئۆچۈرۈلدى", {
				position: toast.POSITION.TOP_RIGHT,
				className: "rtoast",
				onClose: () => {

				},
			});
		}else if(res.status==401){
			localStorage.removeItem("token")
			navigate('/auth?from=/player/'+item.movieId+'?episode_id='+item.episodeId);
		}
	}

  const handelSearch = async()=>{
    setLoading(true)
    let name = searchRef.current.value;
  
    let res = await api.req('get', "/search", {
      name
    }, true);
    if (res.status === 200) {
      setLoading(false)
      setResults(res.data);
      getSearchs()
    }
  }

  const renderProducts = ()=>{
    if(products==null){
      return(
        renderLoading()
      )
    }else if(products.length>0){
      return (
        <div className='list'>
          {
            products.map((item,index)=>{
              return(
                <div key={index}
                  onClick={() => {
                    handelPayment(item);
                  }}
                  className="list-item vip"
                >
                  <div className="name">
                    <img className="icon" src={vipIcon}></img>
                    <div className="title">{item.name}</div>
                  </div>
                  <div className="price">
                    {item.price / 100} يۈەن
                  </div>
                </div>
              )
            })
          }
        </div>
      )
      
    }
  }


  const renderLogin = ()=>{
    if(showLogin){
      return(
        <div className="login-tip">
            <div className="tip">سىز تىخى تىزىملىتىپ كىرمەپسىز.</div>
  
          <Link to="/auth?from=/?tab=2" className='login'>ئۈندىدار بىلەن كىرىش</Link>
        </div>
      )
    }
    
  }
  const renderVipDescription = ()=>{
    if(configs==null){
      return renderLoading();
    }else if(configs.hasOwnProperty('vip_description')){
      return(
        <div className="vip-description">
          <div className="desc-title">{configs.vip_description.name}</div>
          {
            parse(configs.vip_description.value)
          }
        </div>
        
      )
    }
  }
  const renderKeywords = (name,searchs)=>{
    if(searchs!=null&&searchs.length>0)
    {
      return(
        <div className="section">
          <div className="section-name">
            {name}
          </div>
          
          <div className="section-content">
            <div className="keywords">
              {
                searchs.map((item,index)=>{
                  return(
                    <div key={index} onClick={()=>{
                      searchRef.current.value=item;
                      handelSearch()
                    }} className="keyword">{item.name}</div>
                  )
                })
              }
            </div>
          </div>
        </div>
      )
    }
  }

  const renderMovies = (movies)=>{
    if(movies!=null){
      return(
        <div className='movies-section'>
          <div className="section-header">
            <div className="name">
              <span>※</span>
              ئىزدەش نەتىجىسى
            </div>
            <div className="sub-name">({movies.length} تال)</div>
          </div>
          <div className="section-content" >
            
            
              <Masonry
                className="movies"
                breakpointCols={3}
                columnClassName="my-masonry-grid_column">
              {
                
  
                movies.map((item,index)=>{
                  return(
                    <div onClick={()=>handelPlay(item)} className="movie" key={index}>
                      <div className="cover-box">
                        <img src={constants.cdn+item.cover+'?x-oss-process=image/resize,w_220,limit_0'} alt={item.name} className="cover" />
                      </div>
                      <div className="name">{item.name}</div>
                    </div>
                  )
                })
              }
              </Masonry>
  
          </div>
          <div className="section-footer"></div>
        </div>
      )
    }
    
  }

  const renderPage = ()=>{
    return(
      <Swiper 
        modules={[]}
        ref={swiperRef}
        slidesPerView={1}
       
        initialSlide={tab}
        onSwiper={(swiper) => {
          // console.log(swiper,'on swiper')
        }}
        onSlideChange={(swiper) => handelPageSwiper(swiper)}
          
      >
      

      <SwiperSlide>
        <div className='page-container'  ref={pageContainerRef}>
        <div className="search-box">
            <div className="search-form">
              <input ref={searchRef} type="text" className="input ug" />
              <div onClick={handelSearch} className="submit">ئىزدەش</div>
            </div>
          </div>
          
          {renderBanners()}
          {renderCats()}

          <div className="search-page">
            {renderKeywords('ئىزدەش خاتېرسى',searchs)}
            {renderMovies(results)}
          </div> 
          
         

          <Movies name='يېڭى' movies={movies} loadMore={loadMore} useWindow={false} scrollParentRef={pageContainerRef.current}/>
          
         
        </div> 
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='page-container'>
        <Movies name='ئاۋات' movies={hotMovies} loadMore={()=>{}}/>
          {renderAlbums()}
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='page-container'>
            {renderLogin()}
          
            <MoviesList name='يېقىنقىلىرى' movies={views} handelRemoveView={handelRemoveView} />
          

        </div>
      </SwiperSlide>
     
      </Swiper>
    )
    
  }
  return(
      <div className='homepage ug'>
        
        <div className='header'>
     
          <nav className='nav'>
            <div onClick={()=>{handelTab(0)}}className={tab==0?'item current':'item'}>تەۋسىيە</div>
            <div onClick={()=>{handelTab(1)}}className={tab==1?'item current':'item'}>ئاۋات</div>
            <div onClick={()=>{handelTab(2)}} className={tab==2?'item current':'item'}>كۆرۈۋاتقانلىرىم</div>
          </nav>
         
        </div>
        {renderPage()}

        <div className='footer'>
     
          <nav className='nav'>
            <div onClick={()=>{navigate('/')}}className='item current'>باشبەت</div>
            {/* <div onClick={()=>{navigate('/moments')}}className='item'>كۆرۈرمەن</div> */}
            
            <div onClick={()=>{navigate('/user')}}className='item'>مېنىڭ</div>
          </nav>
         
        </div>
        <ToastContainer rtl draggablePercent={60} autoClose={800} />
      </div>
  )
}
