import 'whatwg-fetch';

import Constants from './constants';


const req = async (method = 'get', url, param = {}, whithToken = false) => {
	param.terminal = Constants.terminal;
	let token = localStorage.getItem('token');
	let options = null;
	if (whithToken) {
		options = {
			method: method,
			headers: {
				'content-type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		}
		
	} else {
		options = {
			// credentials: 'include',
			method: method,
			headers: {
				'content-type': 'application/json'
			},
		}

	}

	if (method == 'get') {
		// console.log(options,'options')
		let reqUrl = new URL(Constants.rest + url);
		for (let k in param) {
			reqUrl.searchParams.append(k, param[k]);
		}
		let response = await fetch(reqUrl, options);
		if(response.status===401){
			localStorage.removeItem('token')
		}
		return {
			data: await response.json(),
			status: response.status
		};
	} else {
		options.body = JSON.stringify(param);
		let response = await fetch(Constants.rest + url, options);
		if(response.status===401){
			localStorage.removeItem('token')
		}
		return {
			data: await response.json(),
			status: response.status
		};
	}
}

const uploadFile = async (id, file) => {

	let formData = new FormData();
	formData.append("file", file);
	formData.append("bookId", id)

	return fetch(Constants.api + 'file-image-upload', {
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
		},
		method: "POST",
		body: formData
	}).then((res) => {
		if (res.status === 401) {
			localStorage.removeItem('token');
			return ({
				rc: -7
			})
		}
		if (res.ok) {
			return res.json()
		} else {
			// 服务器异常
			throw Error('');
		}
	}).then((json) => {
		return json;
	}).catch((error) => {
		console.log(error, 'error');
		// message.error('errorMessage')
	})


}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
	req,
	uploadFile
}