import {
  useEffect,
  useState,
  useRef,

} from 'react';

import {
  useParams,
  useSearchParams,
  Link,
  useNavigate
} from 'react-router-dom';

import Masonry from 'react-masonry-css';
import api from "../utils/api";
import '../sass/main.sass';

import search from "../static/search.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import constants from "../utils/constants";

import vipIcon from "../static/vip.png";
import arrowIcon from "../static/left.png";
import contactUs from "../static/opinion.png";
import format from 'date-format';


import {Pagination, EffectFade,Autoplay } from 'swiper';

import { Swiper, SwiperSlide,useSwiper,useSwiperSlide, } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';


import parse from 'html-react-parser';


import renderLoading from '../components/loading';

export default function UserPage(){

  //common
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab')||1;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const swiperRef = useRef();

 
  //userpage
  const [configs, setConfigs] = useState(null);
  const [user, setUser] = useState(null);
  const [userVip, setUserVip] = useState(null);
  const [products, setProducts] = useState(null);

 
  const [showLogin,setShowLogin] = useState(false);
 
  useEffect(() => {
    
    getUser();
    getConfigs();
    getProducts();
    getUserVip();
  }, []);

  

  const getConfigs = async () => {
    let res = await api.req('get', "/configs", {
      // categoryId: 1
    }, true);
    if (res.status === 200) {
      let configs = {};
      res.data.map((item) => {
        configs[item.key] = item;
      });
      setConfigs(configs);
    }
  };

  const getProducts = async () => {
    let res = await api.req('get', "/products", {
      orderBy: 'asc',
      sortBy: 'price'
    }, true);
    if (res.status === 200) {
      
      setProducts(res.data);
    }
  };

  const getUser = async () => {
    let res = await api.req('get', "/users/1", {
    }, true);
    if (res.status === 200) {
      
      setUser(res.data);
    }
  };

  const getUserVip = async () => {
    let res = await api.req('get', "/vip-users/1", {
    }, true);
    if (res.status === 200) {
      setUserVip(res.data);
    }
  };

  const handelPayment = (product) => {
    createOrder(product);
  };

  const createOrder = async(product) => {
    let res = await api
      .req("post","/orders", {
        name: product.name,
				amount: product.price,
				productId: product.id
      },true);
			if(res.status==201){
				payment(res.data);
			}
      
  };
  const payment = (data) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: "wx00be5279b25ccc09", //公众号ID，由商户传入
        timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: data.nonceStr, //随机串
        package: data.package,
        signType: data.signType, //微信签名方式：
        paySign: data.paySign, //微信签名
      },
      function (res) {
        // console.log(res, "res3");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          toast.success("پۇل تۆلەندى", {
            position: toast.POSITION.TOP_RIGHT,
            className: "rtoast",
            onClose: () => {
              
            },
          });
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          getUserVip();
        } else {
          toast.warning("پۇل تۆلەش مەغلۇب بولدى", {
            position: toast.POSITION.TOP_RIGHT,
            className: "rtoast",
            onClose: () => {
              // props.history.push("/order?store_id="+cityList.id);
            },
          });
        }
      }
    );
  };

  
 

  

  const handelTab = (url)=>{
    navigate("/"+url);
  }
  
  const handelSplide = (banner)=>{
    // console.log(banner,'handelBnnerSwipe')
    navigate(banner.link)
  }
  const handelPageSwiper = (swiper)=>{
    // setTab(swiper.activeIndex)
    setSearchParams({
      tab: swiper.activeIndex
    })
    navigate("?tab="+swiper.activeIndex)
  }



	

  const renderProducts = ()=>{
    if(products==null){
      return(
        renderLoading()
      )
    }else if(products.length>0){
      return (
        <div className='list'>
          {
            products.map((item,index)=>{
              return(
                <div key={index}
                  onClick={() => {
                    handelPayment(item);
                  }}
                  className="list-item vip"
                >
                  <div className="name">
                    <img className="icon" src={vipIcon}></img>
                    <div className="title">{item.name}</div>
                  </div>
                  <div className="price">
                    {item.price / 100} يۈەن
                  </div>
                </div>
              )
            })
          }
        </div>
      )
      
    }
  }


  const renderLogin = ()=>{
    if(showLogin){
      return(
        <div className="login-tip">
            <div className="tip">سىز تىخى تىزىملىتىپ كىرمەپسىز.</div>
  
          <Link to="/auth?from=/?tab=2" className='login'>ئۈندىدار بىلەن كىرىش</Link>
        </div>
      )
    }
    
  }
  const renderVipDescription = ()=>{
    if(configs==null){
      return renderLoading();
    }else if(configs.hasOwnProperty('vip_description')){
      return(
        <div className="vip-description">
          <div className="desc-title">{configs.vip_description.name}</div>
          {
            parse(configs.vip_description.value)
          }
        </div>
        
      )
    }
  }
  

  const renderPage = ()=>{
    return(
 
      <div className='page-container'>
          <div className="user-page">
            {
              user? <div className="userinfo">
            
              <img
                bindtap="binddivTap"
                className="avatar"
                src={user.avatarUrl}
                mode="cover"
              ></img>
              <div className="meta">
                <span className="nickname">{user.nickName}</span>
  
                
                <div className="id">
                  <div className="text">ID: {user.id}</div>
                   {/* <img className="copy-icon" src={copyIcon} />  */}
                </div>
              </div>
              {userVip?
                <div className="expire-at">
                <div className="text">ئالىي ئەزا ۋاقتى</div>
                <div className="date">
                  {format('yyyy-MM-dd hh:mm:ss', new Date(userVip.expire_at*1000))}
                </div>
                
              </div>:"" 
              }
              

            </div>
            :<div className="login-box">
            <div className="login-tip">
                كىرگەندىن كېين تېخىمۇ كۆپ مەزمۇنلاردىن بەھىرلىنەلەيسىز
              </div>
              <Link to="auth" className="login ug">ئۈندىداردا   كىرىش</Link>
          </div>
            }
            
          
          {
            userVip&&userVip.expire_at*1000< new Date().getTime()?<div className="list">
            <div className="list-item">
              <div className="text tip">
                ئالىي ئەزا ۋاقتىڭىز توشاپ قاپتۇ، تىزدىن ئۇزارتىۋېلىڭ!
              </div>
            </div>
          </div>:''
          }
          
          {renderProducts()}
          {/* <div className="list">
            
            <button open-type="contact" className="contact list-item">
              <img className="icon" src={contactUs}></img>
              <div className="text ug">ئالاقىلىشىش</div>
              <img className="more-icon" src={arrowIcon}></img>
            </button>
          </div> */}

          {renderVipDescription()}
        </div>
        </div>
    )
    
  }
  return(
      <div className='homepage ug'>
        
        
        {renderPage()}

        <div className='footer'>
     
          <nav className='nav'>
            <div onClick={()=>{navigate("/")}}className='item'>باشبەت</div>
            {/* <div onClick={()=>{navigate("/moments")}}className='item'>كۆرۈرمەن</div> */}
            
            <div onClick={()=>{navigate("/user")}}className='item current'>مېنىڭ</div>
          </nav>
         
        </div>
        <ToastContainer rtl draggablePercent={60} autoClose={800} />
      </div>
  )
}
